<template>
    <v-container fluid class="pt-0 mt-0">
        <template v-if="Object.keys(turma).length > 0">
            <HeaderAzul titulo="Learning Map" :subtitulo="turma.curso.curso" />
            <v-row  v-if="Object.keys(turma).length > 0" class="justify-center mt-2">
                <v-col cols="12" class="mb-0 pb-0">
                    <v-card elevation="0" style="background-color: unset;">
                        <v-card-title style="font-size: 25px; color: white;" class="pb-3">
                            {{turma.turma}}
                            <v-spacer/>
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                    <v-card>
                        <v-card-text class="pt-3 pb-0"><small>{{learningMap[0].level}}</small></v-card-text>
                        <v-card-text class="pt-3 my-0" v-html="`${learningMap[0].frase}`" style="white-space: pre-line; color: #000;"/>
                        <v-divider/>
                        <v-card-text v-show="learningMap[0].resposta_exibir" v-html="learningMap[0].resposta" style="white-space: pre-line;"></v-card-text>
                        <v-divider v-if="learningMap[0].resposta_exibir"/>
                        <v-card-actions>
                            <v-btn :loading="carregando" v-if="!learningMap[0].sei && !learningMap[0].resposta_exibir" text @click="sei(learningMap[0].id_frase, learningMap[0].id_conteudo, learningMap[0].id_level)"><v-icon left>mdi-check</v-icon> Eu sei</v-btn>
                            <v-spacer/>
                            <v-btn :loading="carregando" v-if="!learningMap[0].resposta_exibir" text @click="naoSei(learningMap[0].id_frase, learningMap[0].id_conteudo, learningMap[0].id_level)"><v-icon left>mdi-eye</v-icon> Traduzir</v-btn>
                            <v-btn :loading="carregando" v-if="learningMap[0].resposta_exibir" text @click="removerFrase"><v-icon left>mdi-arrow-right</v-icon> Próximo</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>

import axios from "axios"
import {mapState} from "vuex"
import HeaderAzul from "@/Views/Estrutura/HeaderAzul"

export default {
    name: "LearningMap",
    props : ['id_curso', 'id_turma'],
    components: {HeaderAzul},
    computed : {
        ...mapState(['apiUrl', 'baseUrl'])
    },
    data: () => ({
        turma : {},
        learningMap : [],
        carregando : false
    }),
    methods : {
        removerFrase() {
            this.learningMap.shift()
            if (this.learningMap.length === 0) {
                this.getLearningMap()
            }
        },
        async getCurso() {
            this.carregando = true
            return await axios.post(`${this.apiUrl}turma/get`, {id_turma : parseInt(this.id_turma)}).then( (res) => {
                this.carregando = false
                this.turma = res.data
            })
        },
        async getLearningMap() {
            this.carregando = true
            return await axios.post(`${this.apiUrl}learning_map/listar`, {id_curso : parseInt(this.id_curso)}).then( (res) => {
                this.carregando = false
                this.learningMap = res.data.lista.map((v) => {return {...v, sei : false, resposta_exibir : false}})
            })
        },
        async sei(id_frase, id_conteudo, id_level) {
            this.carregando = true
            await axios.post(`${this.baseUrl}aula/conteudo_frase_sei`, {id_turma : this.id_turma, id_disciplina : this.turma.id_disciplina, id_curso : this.id_curso, id_conteudo, id_frase, id_level}).then(()=>{
                this.carregando = false
                this.removerFrase()
            })
        },
        async naoSei(id_frase, id_conteudo, id_level) {
            this.carregando = true
            await axios.post(`${this.baseUrl}aula/conteudo_frase_nao_sei`, {id_turma : this.id_turma, id_disciplina : this.turma.id_disciplina , id_curso : this.id_curso, id_conteudo, id_frase, id_level}).then(()=>{
                this.carregando = false
                this.learningMap[0].resposta_exibir = true
            })
        }
    },
    async activated() {
        await this.getCurso()
        await this.getLearningMap()
    },
}
</script>

<style scoped>

</style>